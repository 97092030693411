import React from "react";
import LazyBackground from "../../components/loader/loader.component";
import about1 from "../../assets/img/about.jpg";
import about2 from "../../assets/img/fun.jpg";
import about3 from "../../assets/img/any.png";
import about4 from "../../assets/img/event.jpg";
import "./about.styles.scss";

const AboutPage = () => (
  <div>
    <div className="about">
      <LazyBackground
        value1={0}
        value2={0.7}
        src={about1}
        className="about__featured-1"
        placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
      >
        <h1 className="about-text">GREAT</h1>
      </LazyBackground>
      <LazyBackground
        value1={0}
        value2={0.7}
        src={about2}
        className="about__featured-2"
        placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
      >
        <h1 className="about-text">FOR</h1>
      </LazyBackground>
    </div>
    <div className="about">
      <LazyBackground
        value1={0}
        value2={0.7}
        src={about3}
        className="about__featured-3"
        placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
      >
        <h1 className="about-text">ANY</h1>
      </LazyBackground>

      <LazyBackground
        value1={0}
        value2={0.7}
        src={about4}
        className="about__featured-4"
        placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
      >
        <h1 className="about-text">EVENT</h1>
      </LazyBackground>
    </div>
  </div>
);

export default AboutPage;
