import SHOP_DATA from "../../assets/data/shop_data.js";
const INITIAL_STATE = {
  shop_collections: SHOP_DATA,
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default shopReducer;
