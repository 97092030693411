import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import "./collection-overview.styles.scss";

import CollectionRow from "../collection-row/collection-row.component";

import { selectCollectionsForPreview } from "../../redux/shop/shop.selectors";

const CollectionOverview = ({ shop_collections }) => (
  <div className="overview">
    <div className="overview__cake-pops">
      <CollectionRow
        data={shop_collections}
        row={1}
        route={"/shop/cake-pops"}
        buttonStyle="two"
      />
    </div>
    <div className="overview__cookies">
      <CollectionRow
        data={shop_collections}
        row={2}
        route={"/shop/cookies"}
        buttonStyle="three"
      />
    </div>
    <div className="overview__misc">
      <CollectionRow
        data={shop_collections}
        row={3}
        route={"/shop/miscellaneous"}
        buttonStyle="two"
      />
    </div>
  </div>
);

const mapStateToProps = createStructuredSelector({
  shop_collections: selectCollectionsForPreview,
});
export default connect(mapStateToProps)(CollectionOverview);
