import React from "react";
import { Link } from "react-router-dom";
import "./faq.styles.scss";

const FAQ = () => {
  return (
    <div className="faq">
      <h1 className="faq__title">GOT QUESTIONS? WE HAVE ANSWERS</h1>
      <div className="faq__body">
        <div className="cell">
          <h2>How can I customize my order?</h2>
          <p>
            For any customization inquiries please contact us{" "}
            <Link to="/contact">here</Link>!
          </p>
        </div>
        <div className="cell">
          <h2>Can I specify a delivery time for my order?</h2>
          <p>
            We allow you to pick your delivery time from between 9am and 6pm in
            checkout!
          </p>
        </div>
        <div className="cell">
          <h2>What is your cancellation policy?</h2>
          <p>
            Orders can be cancelled up to 3 days before order date. Please
            contact us using our <Link to="/contact">Contact Form</Link> or
            email us at natsweetooth@gmail.com to request cancellation. Please
            be sure to include in the subject line that you’re seeking a
            cancellation, (i.e. [Cancellation] and Full Name).
          </p>
        </div>
        <div className="cell">
          <h2>What is your return policy?</h2>
          <p>
            Nat Sweet Tooth does not accept returns, offer exchanges, and/or
            grant refunds on any goods sold unless the issue for return,
            exchange, and/or refund is the result of an error in our
            manufacturing, packaging, or handling of the goods.
          </p>
        </div>
        <div className="cell">
          <h2>I have a different question?</h2>
          <p>
            For any other questions please contact us{" "}
            <Link to="/contact">here</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
