import React from "react";
import "./notice.styles.scss";

const Notice = () => (
  <div className="notice">
    <h3>
      We are currently only selling to the{" "}
      <span className="keyword">Los Angeles</span> area. Join our newsletter to
      get updates!
    </h3>
  </div>
);
export default Notice;
