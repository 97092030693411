const SHOP_DATA = {
  "cake-pops": {
    id: 1,
    title: "Cake Pops",
    routeName: "cake-pops",
    items: [
      {
        id: 1,
        name: "Rose Petal Cakepops",
        imageUrl: "https://i.ibb.co/k1wMZbX/cakepop1.jpg",
        price: 40,
        quantityType: "dozen",
        description: "Chocolate dipped vanilla cakepops with rose petals.",
      },
      {
        id: 2,
        name: "Rainbow Cakepops",
        imageUrl: "https://i.ibb.co/K0JGqn1/cakepop2.jpg",
        price: 33,
        quantityType: "dozen",
        description: "Vanilla cakepops covered with rainbow sprinkles.",
      },
      {
        id: 3,
        name: "Luau Cakepops",
        imageUrl: "https://i.ibb.co/Xkwrm9f/cakepop3.jpg",
        price: 40,
        quantityType: "dozen",
        description: "Vanilla cakepops with yellow sprinkles and flowers.",
      },
      {
        id: 4,
        name: "Pink Flower Cakepops",
        imageUrl: "https://i.ibb.co/mtjHvr8/cakepop4.jpg",
        price: 40,
        quantityType: "dozen",
        description:
          "Vanilla cakepops with small yellow flowers made of fondant.",
      },
      {
        id: 5,
        name: "Baby Shark Cakepops",
        imageUrl: "https://i.ibb.co/FhZ7Dtv/cakepop4.jpg",
        price: 40,
        quantityType: "dozen",
        description:
          "Baby shark themed vanilla cakepops featured in red, blue, green, and yellow.",
      },
      {
        id: 6,
        name: "Pumpkin Cakepops",
        imageUrl: "https://i.ibb.co/XXHVj9W/cakepop6.jpg",
        price: 40,
        quantityType: "dozen",
        description:
          "Vanilla pumpkin shaped cakepops with green fondant on top.",
      },
      {
        id: 7,
        name: "Minnie Mouse Cakepops",
        imageUrl: "https://i.ibb.co/NYYQTnb/cakepop7.jpg",
        price: 40,
        quantityType: "dozen",
        description:
          "Minnie Mouse themed vanilla cakepops with fondant bow on top.",
      },
      {
        id: 8,
        name: "Turkey Cakepops",
        imageUrl: "https://i.ibb.co/6WwKfbV/cakepop9.jpg",
        price: 40,
        quantityType: "dozen",
        description: "Turkey themed vanilla cakepops with candy corn on top.",
      },
    ],
  },
  cookies: {
    id: 2,
    title: "Cookies",
    routeName: "cookies",
    items: [
      {
        id: 9,
        name: "Halloween Cookie Kit",
        imageUrl: "https://i.ibb.co/7491FqB/cookie1.png",
        price: 25,
        quantityType: "each",
        description:
          "Cookie kit with 3 bags of icing, 4 bags of sprinkles, and 12 cookies.",
      },
      {
        id: 10,
        name: "Thanksgiving Cookie Box",
        imageUrl: "https://i.ibb.co/rdHYSmb/cookie2.jpg",
        price: 30,
        quantityType: "each",
        description:
          "Box of 20 cookies shaped as a turkey, leaf, pie, or pumpkin.",
      },
      {
        id: 11,
        name: "Yoda One Cookie Set",
        imageUrl: "https://i.ibb.co/87h0csr/cookie3.png",
        price: 12,
        quantityType: "each",
        description:
          "Yoda themed cookie set that includes a heart shaped cookie and a yoda shaped cookie.",
      },
      {
        id: 12,
        name: "Bee Mine Cookie Set",
        imageUrl: "https://i.ibb.co/N9kq3fJ/cookie4.png",
        price: 15,
        quantityType: "each",
        description:
          "Set of 5 bee themed cookies wrapped in a Valentine's Day mailbox.",
      },
      {
        id: 13,
        name: "Pizza My Heart Cookie",
        imageUrl: "https://i.ibb.co/qjGrbm2/cookie5.png",
        price: 10,
        quantityType: "each",
        description: "Pizza shaped cookie with mini heart toppings on top.",
      },
      {
        id: 14,
        name: "Christmas Cookie Set",
        imageUrl: "https://i.ibb.co/CKB5nrm/cookie8.jpg",
        price: 15,
        quantityType: "each",
        description:
          "Set of 4 cookies in a snow globe including a santa shaped cookie.",
      },
      {
        id: 15,
        name: "Sunflower Boquet",
        imageUrl: "https://i.ibb.co/kgHjC0X/cookie7.jpg",
        price: 20,
        quantityType: "dozen",
        description: "12 sunflower shaped cookies represented as a boquet.",
      },
      {
        id: 16,
        name: "DIY Turkey Cookie",
        imageUrl: "https://i.ibb.co/S6RMwRk/cookie6.jpg",
        price: 8,
        quantityType: "each",
        description:
          "Turkey themed sugar cookie that you can paint yourself before eating.",
      },
    ],
  },
  miscellaneous: {
    id: 3,
    title: "Miscellaneous",
    routeName: "miscellaneous",
    items: [
      {
        id: 17,
        name: "Strawberry Tower",
        imageUrl: "https://i.ibb.co/hRdCKhs/random3.jpg",
        price: 150,
        quantityType: "each",
        description:
          "Tower with two dozen yellow roses and white chocolate dipped strawberries.",
      },
      {
        id: 18,
        name: "Birthday Strawberries",
        imageUrl: "https://i.ibb.co/YhY79MM/misc1.jpg",
        price: 48,
        quantityType: "each",
        description:
          "Boxed chocolate covered strawberries with 'HAPPY BDAY LOVE' in fondant.",
      },
      {
        id: 19,
        name: "Sea Shells Pretzel Sticks",
        imageUrl: "https://i.ibb.co/7rD1QnT/random1.jpg",
        price: 36,
        quantityType: "dozen",
        description:
          "Dipped and drizzled pretzel rods decorated with sea themed accents and sugar pearls",
      },
      {
        id: 20,
        name: "Rice Krispy Treats",
        imageUrl: "https://i.ibb.co/wCwzpL3/rkts5.jpg",
        price: 48,
        quantityType: "dozen",
        description:
          "Pink drizzle with fondant flowers individually bagged with a satin ribbon",
      },
      {
        id: 21,
        name: "Hazelnut Delight",
        imageUrl: "https://i.ibb.co/VTnS7P0/random4.jpg",
        price: 20,
        quantityType: "dozen",
        description: "Delicious puff pastry filled with hazelnut spread",
      },
    ],
  },
  popular: {
    id: 4,
    title: "Popular Menu Items",
    routeName: "popular",
    items: [
      {
        id: 22,
        name: "Yoda One Cookie Set",
        imageUrl: "https://i.ibb.co/87h0csr/cookie3.png",
        price: 12,
        quantityType: "each",
        description:
          "Yoda themed cookie set that includes a heart shaped cookie and a yoda shaped cookie.",
      },
      {
        id: 23,
        name: "Pizza My Heart Cookie",
        imageUrl: "https://i.ibb.co/qjGrbm2/cookie5.png",
        price: 10,
        quantityType: "each",
        description: "Pizza shaped cookie with mini heart toppings on top.",
      },
      {
        id: 24,
        name: "Sea Shells Pretzel Sticks",
        imageUrl: "https://i.ibb.co/7rD1QnT/random1.jpg",
        price: 36,
        quantityType: "dozen",
        description:
          "Dipped and drizzled pretzel rods decorated with sea themed accents and sugar pearls",
      },
      {
        id: 25,
        name: "Baby Shark Cakepops",
        imageUrl: "https://i.ibb.co/FhZ7Dtv/cakepop4.jpg",
        price: 40,
        quantityType: "dozen",
        description:
          "Baby shark themed vanilla cakepops featured in red, blue, green, and yellow.",
      },
      {
        id: 26,
        name: "Bee Mine Cookie Set",
        imageUrl: "https://i.ibb.co/N9kq3fJ/cookie4.png",
        price: 15,
        quantityType: "each",
        description:
          "Set of 5 bee themed cookies wrapped in a Valentine's Day mailbox.",
      },
      {
        id: 27,
        name: "Hazelnut Delight",
        imageUrl: "https://i.ibb.co/VTnS7P0/random4.jpg",
        price: 20,
        quantityType: "dozen",
        description: "Delicious puff pastry filled with hazelnut spread",
      },
      {
        id: 28,
        name: "Minnie Mouse Cakepops",
        imageUrl: "https://i.ibb.co/NYYQTnb/cakepop7.jpg",
        price: 40,
        quantityType: "dozen",
        description:
          "Minnie Mouse themed vanilla cakepops with fondant bow on top.",
      },
      {
        id: 29,
        name: "Turkey Cakepops",
        imageUrl: "https://i.ibb.co/6WwKfbV/cakepop9.jpg",
        price: 40,
        quantityType: "dozen",
        description: "Turkey themed vanilla cakepops with candy corn on top.",
      },
    ],
  },
  birthday: {
    id: 5,
    title: "Nat's Birthday Picks",
    routeName: "birthday",
    items: [
      {
        id: 30,
        name: "Rice Krispy Treats",
        imageUrl: "https://i.ibb.co/wCwzpL3/rkts5.jpg",
        price: 48,
        quantityType: "dozen",
        description:
          "Pink drizzle with fondant flowers individually bagged with a satin ribbon",
      },
      {
        id: 31,
        name: "Rainbow Cakepops",
        imageUrl: "https://i.ibb.co/K0JGqn1/cakepop2.jpg",
        price: 33,
        quantityType: "dozen",
        description: "Vanilla cakepops covered with rainbow sprinkles.",
      },
      {
        id: 32,
        name: "Birthday Strawberries",
        imageUrl: "https://i.ibb.co/YhY79MM/misc1.jpg",
        price: 48,
        quantityType: "each",
        description:
          "Boxed chocolate covered strawberries with 'HAPPY BDAY LOVE' in fondant.",
      },
      {
        id: 33,
        name: "Hazelnut Delight",
        imageUrl: "https://i.ibb.co/VTnS7P0/random4.jpg",
        price: 20,
        quantityType: "dozen",
        description: "Delicious puff pastry filled with hazelnut spread",
      },
      {
        id: 34,
        name: "Pink Flower Cakepops",
        imageUrl: "https://i.ibb.co/mtjHvr8/cakepop4.jpg",
        price: 40,
        quantityType: "dozen",
        description:
          "Vanilla cakepops with small yellow flowers made of fondant.",
      },
    ],
  },
};

export default SHOP_DATA;
