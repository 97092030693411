import React from "react";

import "./text-input.styles.scss";

const TextInput = ({ handleChange, label, ...otherProps }) => (
  <div className="text">
    <textarea
      cols="30"
      rows="5"
      className="text__input"
      onChange={handleChange}
      {...otherProps}
    />
    {label ? (
      <label
        className={`${
          otherProps.value.length ? "shrink" : ""
        } text__input__label `}
      >
        {label}
      </label>
    ) : null}
  </div>
);

export default TextInput;
