import React from "react";
import "./tos.styles.scss";
const TOSPage = () => {
  return (
    <div className="tos">
      <h1 className="tos__title">NAT'S SWEET TOOTH TERMS OF USE</h1>
      <div className="tos__body">
        <p>
          By using this Website, you agree to be bound by and comply with these
          Terms of Use and any other guidelines, rules or policies posted to the
          Website by Nat's Sweet Tooth from time to time. All such guidelines,
          rules and policies (including, without limitation, Nat's Sweet Tooth’s
          ) are incorporated into these Terms of Use by reference and are made a
          part hereof. Please read these Terms of Use carefully. This is a legal
          document and it contains binding obligations.
        </p>
        <h2>Changes to Terms of Use</h2>
        <p>
          Nat's Sweet Tooth shall have the right to modify these Terms of Use at
          any time, which modification shall be effective immediately following
          Nat's Sweet Tooth’s posting of such change on the Website. We
          recommend that you check the Website regularly for any such changes.
          Your use of the Website following such posting shall be deemed to
          constitute your acceptance of such modification.
        </p>
        <h2>Use of this Website</h2>
        <p>
          Access, distribution and/or use of this Website is subject to all
          applicable laws and regulations. To the extent that access,
          distribution and/or use of this Website would be deemed illegal by
          applicable law, such access, distribution and/or use is prohibited.
          You agree to comply with all applicable laws in connection with your
          use of the Website and your participation in any Nat's Sweet Tooth
          offerings. You agree that you will not (i) interfere with the Website
          and/or the servers or networks connected to same; (ii) “frame” or
          “mirror” any part of the Website without Nat's Sweet Tooth’s prior
          written authorization; (iii) use metatags or code or other devices
          containing any reference to any Nat's Sweet Tooth offerings in order
          to direct any person to any other website for any purpose; and/or (iv)
          modify, translate, decompile, disassemble, or reverse engineer any
          software available through the Website or otherwise used in connection
          with Nat's Sweet Tooth’s offerings.
        </p>
        <h2>Use of Materials</h2>
        <p>
          Materials on this Website, including but not limited to sound
          recordings, videos, images, logos, trademarks and other works
          (collectively, the “Materials”), are protected under various
          intellectual property laws and owned or controlled by Nat's Sweet
          Tooth, or used with permission of the owner(s) of such Materials. The
          display of Materials on this Website does not imply that a license of
          any kind has been granted to you. You agree that you will not remove
          any copyright, trademark or other proprietary rights notices contained
          on the Website. Except as otherwise indicated on this Website,
          copying, reproducing, uploading, downloading, transmitting or any
          other use of this Website or of any of the Materials, in whole or
          part, without the express permission of Nat's Sweet Tooth, is
          prohibited. You may download digital files only where expressly
          permitted. Any unauthorized access to, use or copying of this Website
          and/or the Materials may subject you to liability under applicable
          law, and may result in legal action.
        </p>
        <h2>Copyright Infringement Policy</h2>
        <p>
          Nat's Sweet Tooth values intellectual property and respects the
          intellectual property rights of others, and will remove materials on
          its Website that infringe the copyrights of others. If you believe
          that your copyrighted material has been infringed by material
          contained on this Website, please contact us at
          natsweetooth@gmail.com.
        </p>
        <h2>Limitation of Liability and Use Disclaimer</h2>
        <p>
          Nat's Sweet Tooth endeavors to make sure that all information and data
          it originates on the Website is accurate. However, Nat's Sweet Tooth
          is not responsible for any damages or loss related to the use of this
          Website. You are responsible for undertaking suitable precautions to
          scan for computer viruses and maintaining a backup of all data and/or
          equipment. Nat's Sweet Tooth is not responsible for any damages or
          loss related to your reliance on any outdated version of these Terms
          of Use, including, but not limited, to any outdated version of our
          Privacy Policy. Certain jurisdictions prohibit the limitation of
          liability for certain types of damages or prohibit waivers against
          future fraud, and accordingly, such limitations or waivers may not
          apply to you. In such jurisdictions, Nat's Sweet Tooth’s liability is
          limited to the greatest extent allowable under applicable law.
        </p>
        <h2>Entire Agreement</h2>
        <p>
          These Terms of Use, including all documents and policies referenced
          herein, represent the entire understanding between Nat's Sweet Tooth
          and you regarding your use of this Website and supersede any prior
          statements or representations.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions concerning this Website or any of the
          policies set forth in these Terms of Use, please contact us at
          natsweetooth@gmail.com.
        </p>
      </div>
    </div>
  );
};
export default TOSPage;
