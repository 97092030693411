import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { auth } from "../../firebase/firebase.utils";
import { toggleNavbarHidden } from "../../redux/navbar/navbar.actions";
import { selectNavbarSideDrawer } from "../../redux/navbar/navbar.selector";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import "./side-nav.styles.scss";

const SideNav = ({ sideDrawerOpen, currentUser, dispatch }) => {
  let drawerClasses = "side-drawer";
  if (sideDrawerOpen) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div className={drawerClasses}>
      <ul className="side-options">
        <Link
          className="side-options__link"
          to="/"
          onClick={() => {
            dispatch(toggleNavbarHidden());
          }}
        >
          HOME
        </Link>
        <Link
          className="side-options__link"
          to="/shop"
          onClick={() => {
            dispatch(toggleNavbarHidden());
          }}
        >
          SHOP
        </Link>
        <Link
          className="side-options__link"
          to="/contact"
          onClick={() => {
            dispatch(toggleNavbarHidden());
          }}
        >
          CONTACT
        </Link>
        <Link
          className="side-options__link"
          to="/about"
          onClick={() => {
            dispatch(toggleNavbarHidden());
          }}
        >
          ABOUT
        </Link>
        {currentUser ? (
          <div
            className="side-options__link"
            onClick={() => {
              auth.signOut();
              dispatch(toggleNavbarHidden());
            }}
          >
            SIGN OUT
          </div>
        ) : (
          <Link
            className="side-options__link"
            to="/login"
            onClick={() => {
              dispatch(toggleNavbarHidden());
            }}
          >
            LOGIN
          </Link>
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sideDrawerOpen: selectNavbarSideDrawer,
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(SideNav);
