import React from "react";

class LazyBackground extends React.Component {
  state = { src: null };

  componentDidMount() {
    const { src } = this.props;
    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      this.setState({ src });
    };
  }

  render() {
    return (
      <div
        {...this.props}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, ${
            this.props.value1
          }), rgba(0, 0, 0, ${this.props.value2})),url(${
            this.state.src || this.props.placeholder
          })`,
        }}
      />
    );
  }
}

export default LazyBackground;
