import React from "react";
import "./privacy.styles.scss";
const PrivacyPage = () => {
  return (
    <div className="privacy">
      <div className="privacy-title">NAT'S SWEET TOOTH PRIVACY POLICY</div>
      <div className="privacy-body">
        <h2>Information Use</h2>
        <p>
          Nat's Sweet Tooth collects personally identifiable information from
          our users at several different points on our Websites. By ordering our
          products and using our services, you are consenting to the data
          collection and use practices described in this Privacy Policy, as
          modified from time to time by us in our sole discretion. We reserve
          the right to modify our Policy and invite you to consult this Privacy
          Policy from time to time in order to familiarize yourself with any
          changes. We will post any new Privacy Policy on our Websites, changing
          the date at the top of such Privacy Policy.
        </p>
        <h2>Registration</h2>
        <p>
          Registration is not required, but is optional and will speed and
          simplify online shopping for returning visitors. During registration
          you are required to give contact information (such as name and email
          address). We use this information to contact you about your order.
          When you register, you will receive a confirming email. See the
          “Communications” section for additional information.
        </p>
        <h2>Orders</h2>
        <p>
          If you purchase a product or service from us, we will request certain
          personally identifiable information from you on our order form. You
          must provide contact information (such as name, email, and shipping
          address) and financial information (such as credit card number,
          expiration date). We use this information for billing purposes and to
          fill your orders. If we have trouble processing an order, we will use
          this information to contact you.
        </p>
        <h2>Uses of Information</h2>
        <p>
          Nat's Sweet Tooth collects your information in order to facilitate
          your participation in the activities you select and to prevent fraud
          and unlawful use. If you purchase or register for our products and
          services, for example, the information is used to fulfill your order.
          If you enter a contest, information is collected to qualify the entry
          and to contact you regarding the contest or prize awards. Your
          personal information may also be used to track customer preferences to
          provide a customized Site experience. Nat's Sweet Tooth may collect
          information about the use and ordering of our products and services
          and the Site, including, without limitation, product ordering
          information, types of services used, how many users we receive daily,
          Site pages visited, and the internet protocol (“IP”) address of your
          computer. By submitting your data, you expressly consent to such uses.
          This information is generally collected in aggregate form, without
          identifying any user individually, although IP address and Session ID
          in relation to purchases and downloads may be tracked as part of Nat's
          Sweet Tooth’s customer order review and fraud prevention efforts.
          Other exceptions to this data collection, where an individual may be
          identified individually, are noted in this Privacy Policy or in
          additional privacy terms connected to a specific product, service or
          Website. Nat's Sweet Tooth may use aggregate, nonidentifying
          statistical data for improving the Sites and our products and
          services, providing more relevant experiences to our customers and for
          statistical analysis.
        </p>
        <h2>Newsletters</h2>
        <p>
          If you wish to subscribe to our newsletter(s), we will use your email
          address to send the newsletter to you. Out of respect for your
          privacy, if you would like to unsubscribe, please let us know at
          natsweetooth@gmail.com.
        </p>
        <h2>Legal Disclaimer</h2>
        <p>
          We reserve the right to disclose your personally identifiable
          information as required by law and when we believe that disclosure is
          necessary to protect our rights and/or to comply with a judicial
          proceeding, court order, or legal process served on our Website.
        </p>
        <h2>Cookies</h2>
        <p>
          A cookie is a small text file that is stored on a user’s computer for
          record-keeping purposes. We use cookies on our Site. We do not link
          the information we store in cookies to any personally identifiable
          information you submit while on our Site. We use both session ID
          cookies and persistent cookies. We use session cookies to make it
          easier for you to navigate our Site. A session ID cookie expires when
          you close your browser. A persistent cookie remains on your hard drive
          for an extended period of time.
        </p>
        <h2>Security</h2>
        <p>
          We follow generally accepted industry standards to protect the
          personal information submitted to us, both during transmission and
          once we receive it. However, no method of transmission over the
          Internet, or method of electronic storage, is 100% secure. Therefore,
          while we strive to use commercially acceptable means to protect your
          personal information, we cannot guarantee its absolute security. If
          you have any questions about security on our Website, you can send
          email us at natsweetooth@gmail.com.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or suggestions regarding this Privacy
          Policy, please contact us at: natsweetooth@gmail.com
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
