import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { auth } from "../../firebase/firebase.utils";
import CartIcon from "../cart-icon/cart-icon.component";
import CartDropdown from "../cart-dropdown/cart-dropdown.component";
import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";

import "./header.styles.scss";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import ToggleButton from "../toggle-button/toggle-button.component";
import Notice from "../../components/notice/notice.component";
const Header = ({ currentUser, hidden }) => (
  <div className="header">
    <Notice />
    <div className="navbar">
      <div className="navbar__toggle-button">
        <ToggleButton />
      </div>
      <NavLink className="logo" to="/">
        <Logo />
      </NavLink>
      <ul className="navbar__menu">
        <NavLink
          exact={true}
          activeClassName="is-active"
          className="option"
          to="/"
        >
          Home
        </NavLink>
        <NavLink activeClassName="is-active" className="option" to="/shop">
          SHOP
        </NavLink>

        <NavLink activeClassName="is-active" className="option" to="/contact">
          CONTACT
        </NavLink>
        <NavLink activeClassName="is-active" className="option" to="/about">
          ABOUT
        </NavLink>
        {currentUser ? (
          <div className="option" onClick={() => auth.signOut()}>
            SIGN OUT
          </div>
        ) : (
          <NavLink activeClassName="is-active" className="option" to="/login">
            LOGIN
          </NavLink>
        )}
      </ul>
      <CartIcon />
    </div>
    {hidden ? null : <CartDropdown />}
  </div>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden,
});

export default connect(mapStateToProps)(Header);
