import React from "react";
import { connect } from "react-redux";
import LazyBackground from "../loader/loader.component";
import Button from "../button/button.component";
import { addItem } from "../../redux/cart/cart.actions";

import "./featured-item.styles.scss";

const FeaturedItem = ({ item, addItem }) => {
  const { imageUrl, imageUrlTwo } = item;
  return (
    <>
      <div className="description">
        Description: This classy and elegant two-tier birthday cake is draped in
        homemade fondant with each petal delicately hand-rolled to make the
        effect.
        <br></br> <br></br>Flowers adorned on the top of the cake are hand
        rolled and painted with edible gold paint.
        <br></br>
        <br></br>Serving Size: 48 people
        <br></br>
        <br></br>Price:$250
      </div>
      <div className="featured">
        <div className="featured__item-1">
          <LazyBackground
            value1={0}
            value2={0.5}
            src={imageUrlTwo}
            className="rev-image"
            placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
          />
          <Button onClick={() => addItem(item)} buttonStyle="featured">
            ADD TO CART
          </Button>
        </div>
        <div className="featured__item-2 middle">
          <LazyBackground
            value1={0}
            value2={0.5}
            src={imageUrl}
            className="image"
            placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
          />
          <Button onClick={() => addItem(item)} buttonStyle="featured">
            ADD TO CART
          </Button>
        </div>
        <div className="featured__item-3">
          <LazyBackground
            value1={0}
            value2={0.5}
            src={imageUrlTwo}
            className="image"
            placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
          />
          <Button onClick={() => addItem(item)} buttonStyle="featured">
            ADD TO CART
          </Button>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});

export default connect(null, mapDispatchToProps)(FeaturedItem);
