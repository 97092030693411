import React from "react";
import { withRouter } from "react-router-dom";
import LazyBackground from "../loader/loader.component";
import hero from "../../assets/img/featured.jpg";
import "./featured.styles.scss";

import Button from "../button/button.component";
const Featured = ({ history }) => (
  <>
    <LazyBackground
      value1={0.5}
      value2={0.7}
      src={hero}
      className="featured-hero"
      placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
    >
      <h1>
        Rose Cake<br></br>
        <span>Classy and Delicious.</span>
      </h1>
      <div className="featured-hero__button">
        <Button buttonStyle={"one"} onClick={() => history.push(`/featured`)}>
          View Now
        </Button>
      </div>
    </LazyBackground>
  </>
);

export default withRouter(Featured);
