import React from "react";
import { Link } from "react-router-dom";
import "./footer.styles.scss";

const Footer = () => (
  <div className="foot">
    <div className="foot__social-group">
      <a
        href="https://www.facebook.com/natsweettooth/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <li className="social">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </li>
      </a>
      <a
        href="https://www.instagram.com/natsweetooth/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <li className="social">
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </li>
      </a>
      <a
        href="https://twitter.com/natsweetooth"
        rel="noopener noreferrer"
        target="_blank"
      >
        <li className="social">
          <i className="fa fa-twitter" aria-hidden="true"></i>
        </li>
      </a>
    </div>
    <div className="foot__link-group">
      <Link to="/terms-of-use" className="foot-link">
        Terms of Service
      </Link>
      <Link to="/privacy-policy" className="foot-link">
        Privacy Policy
      </Link>
      <Link to="/faq" className="foot-link">
        FAQ
      </Link>
    </div>
    <h1 className="copyright">NAT'S SWEET TOOTH ALL RIGHTS RESERVED 2020</h1>
  </div>
);

export default Footer;
