import React from "react";

import "./subscribe.styles.scss";

import MailchimpSubscribe from "react-mailchimp-subscribe";
// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = (e) => {
    e.preventDefault();
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  return (
    <form className="newsletter">
      {status === "sending" && <div className="sending">sending...</div>}
      {status === "error" && (
        <div className="error" dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === "success" && (
        <div
          className="success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <br></br>

      <div className="newsletter__field">
        <input
          className="newsletter-email"
          ref={(node) => (email = node)}
          type="email"
          placeholder="Enter Email"
          required
        />
        <br />
        <button className="newsletter__button" onClick={submit}>
          Submit
        </button>
      </div>
    </form>
  );
};
class Subscribe extends React.Component {
  render() {
    return (
      <div className="subscribe">
        <h1 className="subscribe__title">WE SEND REALLY SWEET EMAILS!</h1>
        <MailchimpSubscribe
          url={process.env.REACT_APP_MAILCHIMP_URL}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
            />
          )}
        />
      </div>
    );
  }
}

export default Subscribe;
