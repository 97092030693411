import React from "react";
import ContactForm from "../../components/contact-form/contact-form.component";
import "./contact.styles.scss";

const ContactPage = () => (
  <div className="contact-page">
    <ContactForm />
  </div>
);

export default ContactPage;
