import NavbarActionTypes from "./navbar.types";

const INITIAL_STATE = {
  sideDrawerOpen: false,
};

const navbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavbarActionTypes.TOGGLE_NAVBAR_HIDDEN:
      return {
        ...state,
        sideDrawerOpen: !state.sideDrawerOpen,
      };
    default:
      return state;
  }
};
export default navbarReducer;
