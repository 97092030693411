import React from "react";
import { connect } from "react-redux";
import { toggleNavbarHidden } from "../../redux/navbar/navbar.actions";
import "./toggle-button.styles.scss";

const ToggleButton = ({ dispatch }) => (
  <button
    className="toggle-button"
    onClick={() => {
      dispatch(toggleNavbarHidden());
    }}
  >
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
  </button>
);

export default connect(null, null)(ToggleButton);
