import React from "react";
import ReactDOM from "react-dom";
import ScrollToTop from "react-router-scroll-top";
import ReactOutlineManager from "react-outline-manager";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./redux/store";

import "./index.css";
import App from "./App";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ScrollToTop>
          <ReactOutlineManager>
            <App />
          </ReactOutlineManager>
        </ScrollToTop>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
