import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import "./home-overview.styles.scss";

import CollectionRow from "../collection-row/collection-row.component";

import { selectCollectionsForPreview } from "../../redux/shop/shop.selectors";

const HomeOverview = ({ shop_collections }) => (
  <div>
    <div className="popular">
      <CollectionRow
        data={shop_collections}
        row={4}
        route={"/shop/popular"}
        buttonStyle={"two"}
      />
    </div>
    <div className="birthday">
      <CollectionRow
        data={shop_collections}
        row={5}
        route={"/shop/birthday"}
        buttonStyle={"three"}
      />
    </div>
  </div>
);

const mapStateToProps = createStructuredSelector({
  shop_collections: selectCollectionsForPreview,
});
export default connect(mapStateToProps)(HomeOverview);
