import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CollectionItem from "../../components/collection-item/collection-item.component";

import { selectShopCollection } from "../../redux/shop/shop.selectors";

import "./collection.styles.scss";

const CollectionPage = ({ shop_collection }) => {
  console.log(shop_collection);
  const { title, items } = shop_collection;
  return (
    <div className="category">
      <h2 className="category__title">{title}</h2>

      <Link to="/shop">
        {" "}
        <span>
          <i className="fas fa-long-arrow-alt-left"></i> Go Back
        </span>
      </Link>
      <div className="category__items">
        {items.map((item) => (
          <CollectionItem key={item.id} item={item} buttonStyle="four" />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  shop_collection: selectShopCollection(ownProps.match.params.collectionId)(
    state
  ),
});

export default connect(mapStateToProps)(CollectionPage);
