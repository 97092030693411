import React from "react";
import { withRouter } from "react-router-dom";
import CollectionPreview from "../../components/collection-preview/collection-preview.component";
import Button from "../button/button.component";
import "./collection-row.styles.scss";

const CollectionRow = ({ data, row, route, history, buttonStyle }) => {
  return (
    <>
      {data.slice(row - 1, row).map(({ id, ...otherCollectionProps }) => (
        <CollectionPreview
          key={id}
          buttonStyle={buttonStyle}
          {...otherCollectionProps}
        />
      ))}
      <div className="button-wrapper">
        <Button
          onClick={() => history.push(`${route}`)}
          buttonStyle={buttonStyle}
        >
          View More
        </Button>
      </div>
    </>
  );
};

export default withRouter(CollectionRow);
