import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FormInput from "../form-input/form-input.component";
import TextInput from "../text-input/text-input.component";
import Button from "../button/button.component";
import "./contact-form.styles.scss";
class ContactForm extends React.Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      email: "",
      message: "",
      sent: false,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { fullName, email, message } = this.state;

    let data = {
      fullName,
      email,
      message,
    };
    axios
      .post("/api/form", data)
      .then(
        this.setState({
          sent: true,
          fullName: "",
          email: "",
          message: "",
        })
      )
      .catch(() => {
        console.log("message not send");
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { fullName, email, message } = this.state;
    return (
      <div className="contact">
        <h2 className="contact__title">Something on your mind?</h2>
        <span>
          Please check <Link to="/faq">frequently asked questions</Link> before
          sending us an email!
        </span>
        <form action="" className="contact__form" onSubmit={this.handleSubmit}>
          <FormInput
            type="text"
            name="fullName"
            value={fullName}
            onChange={this.handleChange}
            label="Full Name"
            required="required"
          />
          <FormInput
            type="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            label="Email"
            required="required"
          />
          <TextInput
            type="text"
            name="message"
            value={message}
            onChange={this.handleChange}
            label="Message"
            required="required"
          />

          <div
            className={
              this.state.sent
                ? "contact__form__message--appear"
                : "contact__form__message"
            }
          >
            Message has been sent
          </div>
          <Button buttonStyle="contact" type="submit">
            SUBMIT
          </Button>
        </form>
      </div>
    );
  }
}

export default ContactForm;
