import React from "react";
import { connect } from "react-redux";
import LazyBackground from "../loader/loader.component";
import Button from "../button/button.component";
import { addItem } from "../../redux/cart/cart.actions";

import "./collection-item.styles.scss";

const CollectionItem = ({ item, addItem, buttonStyle }) => {
  const { name, price, quantityType, imageUrl, description } = item;
  return (
    <div className="item">
      <LazyBackground
        value1={0}
        value2={0.3}
        src={imageUrl}
        className="item__image"
        placeholder="https://icon-library.com/images/icon-gif/icon-gif-27.jpg"
      />
      <Button onClick={() => addItem(item)} buttonStyle={buttonStyle}>
        ADD TO CART
      </Button>
      <div className="item__footer">
        <span className="name">{name}</span>
        <span className="price">{`$${price}/${quantityType}`}</span>
      </div>
      <div style={{ clear: "both" }}></div>
      <div className="item__description">
        <p>{description}</p>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});

export default connect(null, mapDispatchToProps)(CollectionItem);
