import memoize from "lodash.memoize";
import { createSelector } from "reselect";

const selectShop = (state) => state.shop;

export const selectShopCollections = createSelector(
  [selectShop],
  (shop) => shop.shop_collections
);

export const selectCollectionsForPreview = createSelector(
  [selectShopCollections],
  (shop_collections) =>
    Object.keys(shop_collections).map((key) => shop_collections[key])
);

export const selectShopCollection = memoize((collectionUrlParam) =>
  createSelector(
    [selectShopCollections],
    (shop_collections) => shop_collections[collectionUrlParam]
  )
);
