import React from "react";

import "./button.styles.scss";

const STYLES = [
  "one",
  "two",
  "three",
  "four",
  "contact",
  "featured",
  "cart",
  "user",
  "google",
];

const Button = ({ children, buttonStyle, ...otherProps }) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[3];

  return (
    <button className={`button button--${checkButtonStyle}`} {...otherProps}>
      {children}
    </button>
  );
};
export default Button;
