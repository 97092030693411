import React from "react";

import "./homepage.styles.scss";

import Featured from "../../components/featured/featured.component";
import HomeOverview from "../../components/home-overview/home-overview.component";
const HomePage = ({ match }) => {
  console.log(match);
  return (
    <div>
      <div className="homepage">
        <Featured />
      </div>
      <HomeOverview />
    </div>
  );
};

export default HomePage;
