import React from "react";
import { connect } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { clearCart } from "../../redux/cart/cart.actions";
const StripeCheckoutButton = ({
  price,
  email,
  cartItems,
  orderDate,
  orderTime,
  history,
  clearCart,
}) => {
  const date = orderDate ? orderDate.toLocaleDateString() : null;
  const time = orderTime.toLocaleTimeString();
  const cartDescription = `${cartItems.map(
    (key) => `${key.name} x ${key.quantity}`
  )} on ${date} at ${time} `;
  console.log(cartDescription);
  const priceForStripe = price * 100;
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLIC;

  const onToken = (token) => {
    axios({
      url: "payment",
      method: "post",
      data: {
        email: email,
        amount: priceForStripe,
        description: cartDescription,
        token: token,
      },
    })
      .then((response) => {
        alert("Succesful Payment. A receipt was sent to your email!");
      })
      .then(() => {
        clearCart();
      })
      .then(() => {
        window.location.href = "http://natsweetooth.com/";
      })
      .catch((error) => {
        console.log("Payment Error: ", error);
        alert(
          "There was an issue with your payment! Please make sure you use the provided credit card."
        );
      });
  };
  return (
    <StripeCheckout
      label="Pay Now"
      name="Nats Sweet Tooth"
      email
      billingAddress
      shippingAddress
      image="https://svgshare.com/i/CUz.svg"
      description={`Your total is $${price}`}
      amount={priceForStripe}
      panelLabel="Pay Now"
      token={onToken}
      stripeKey={publishableKey}
    />
  );
};
const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart()),
});

export default connect(null, mapDispatchToProps)(StripeCheckoutButton);
