import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import CheckoutItem from "../../components/checkout-item/checkout-item.component";
import StripeCheckoutButton from "../../components/stripe-button/stripe-button.component";
import moment from "moment";

import {
  selectCartItems,
  selectCartTotal,
} from "../../redux/cart/cart.selectors";
import "./checkout.styles.scss";

import "react-day-picker/lib/style.css";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-day-picker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000),
      selectedTime: setHours(setMinutes(new Date(), 0), 9),
    };
  }

  setStartDate = (date) => {
    this.setState({
      selectedDay: date,
    });
  };

  handleChange = (date) => {
    this.setState({
      selectedTime: date,
    });
    console.log(this.state.selectedTime);
  };

  render() {
    const { selectedTime, selectedDay } = this.state;
    const { cartItems, total } = this.props;
    let minDate = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
    let blockedDays = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
    let stepTwo = cartItems.length > 0 ? "dt-wrapper" : "dt-wrapper-hidden";
    return (
      <div className="checkout">
        <h2 className="checkout__title">FINALIZE YOUR ORDER!</h2>
        <span>
          Review your order or{" "}
          <Link to="/shop">
            <u>keep shopping</u>
          </Link>
          .
        </span>

        <div className="checkout__wrapper">
          <div className="checkout-header">
            <div className="header-block">
              <span>Product</span>
            </div>
            <div className="header-block">
              <span>Description</span>
            </div>
            <div className="header-block">
              <span>Quantity</span>
            </div>
            <div className="header-block">
              <span>Price</span>
            </div>
            <div className="header-block">
              <span>Remove</span>
            </div>
          </div>
          <div class="cart-items">
            {cartItems.map((cartItem) => (
              <CheckoutItem key={cartItem.id} cartItem={cartItem} />
            ))}
          </div>
          <div className="total">
            <span>TOTAL:${total}</span>
          </div>
          <div className={stepTwo}>
            <div className="delivery">
              <div className="delivery__title">
                <i class="fas fa-truck"></i> Get it delivered as soon as{" "}
                {moment(blockedDays).format("MMMM Do YYYY").toString()}
              </div>
              <div className="delivery__wrapper">
                <div className="delivery__date">
                  <div className="label">Delivery Date</div>

                  <DatePicker
                    selected={selectedDay}
                    onChange={(date) => this.setStartDate(date)}
                    minDate={minDate}
                    onFocus={(e) => e.target.blur()}
                  />
                </div>
                <div className="delivery__time">
                  <div className="label"> Delivery Time</div>
                  <DatePicker
                    selected={this.state.selectedTime}
                    onChange={this.handleChange}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={setHours(setMinutes(new Date(), 0), 9)}
                    maxTime={setHours(setMinutes(new Date(), 0), 18)}
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onFocus={(e) => e.target.blur()}
                  />
                </div>
              </div>
            </div>
            <StripeCheckoutButton
              email
              price={total}
              cartItems={cartItems}
              orderDate={selectedDay}
              orderTime={selectedTime}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});

export default connect(mapStateToProps)(CheckoutPage);
