import React from "react";
import featured from "../../assets/img/featured.jpg";
import featured2 from "../../assets/img/featured2.jpg";
import FeaturedItem from "../../components/featured-item/featured-item.component";
import "./featured.styles.scss";

const FeaturedPage = () => {
  const featuredItem = {
    name: "Rose Cake",
    price: "250",
    imageUrl: featured2,
    imageUrlTwo: featured,
    description: "description",
  };
  return (
    <div class="full">
      <FeaturedItem item={featuredItem} />
    </div>
  );
};

export default FeaturedPage;
